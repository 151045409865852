import React, {useEffect, useState} from 'react'
import {
  getCurrentYear,
  getMonthName,
  getYearList,
  strings,
  toastPush,
} from '../Resources'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import {Divider, FormControl, InputLabel, Select} from '@material-ui/core'
import {useHistory} from 'react-router-dom'
import Header from './Header'
import {overviewStyles} from './Styles'
import Footer from './Footer'
import {
  loadYearlyOverview,
  Month,
  MonthlyStatus,
  OverviewDataSchema,
} from '../backend/DataLoader'
import {redirectToMonthlyRevision} from '../backend/Routing'
import LoadToasts from './Toasts'
import {MaterialUISelectEvent} from '../types'
import {validation} from '../Common'

type OverviewState = {
  year: number
  sentMonths: number[]
  unsentMonths: Month[]
  overallNumbers: number[]
}

const Overview: React.FC = () => {
  const classes = overviewStyles()

  const history = useHistory()

  const [state, setState] = useState<OverviewState>({
    year: getCurrentYear(),
    sentMonths: [],
    unsentMonths: [],
    overallNumbers: [],
  })
  const [monthCards, setMonthCards] = useState<JSX.Element>()

  const getMonthlyStatus = (month: number): MonthlyStatus | null =>
    // Get first item's status; if empty, then  null
    (state.unsentMonths
      .filter((m) => m.month == month)
      ?.map((m) => m.status)[0] as MonthlyStatus) ??
    (state.sentMonths.includes(month) ? MonthlyStatus.SENT : null)

  function getStatusStyleInfo(month: number): [string, boolean] {
    switch (getMonthlyStatus(month)) {
      case MonthlyStatus.UNREVISED:
        return [` ${classes.listItemWarning} `, false]
      case MonthlyStatus.SENT:
        return [` ${classes.listItemDone} `, false]
      case MonthlyStatus.SEMI_REVISED:
      case MonthlyStatus.EDITABLE_AGAIN:
        return [` ${classes.listItemWarningLight} `, false]
      case null:
        return ['', true]
    }
  }

  const openMonthlyRevision = (month: number) =>
    redirectToMonthlyRevision(
      history,
      state.year,
      month,
      getMonthlyStatus(month) ?? undefined
    )

  function setOverviewState(overview: OverviewDataSchema) {
    // number of items for all 12 months
    state.overallNumbers = overview.overallNumbers
    // unsent months with more than zero items
    state.unsentMonths = overview.unsentMonths
    // months with more than zero items, without the unsent ones
    state.sentMonths = Array.from({length: 12}, (_, i) => i + 1).filter(
      (month) =>
        state.overallNumbers[month - 1] > 0 &&
        !state.unsentMonths.map((m) => m.month).includes(month)
    )
  }

  const setEditableAgainStatus = (month: number) => {
    state.unsentMonths.push({
      month: month,
      status: MonthlyStatus.EDITABLE_AGAIN,
    })
    toastPush(strings.toasts.overviewMonthSetToSemiRevised)
    console.log('Month has been set to semi-revised')
    setState({
      ...state,
      sentMonths: state.sentMonths.filter((elem) => elem != month),
    })
  }

  useEffect(() => {
    validation(history).then((isValid) => {
      if (!isValid) {
        return
      }
      console.log(`Year has been changed to ${state.year}`)
      console.log('Getting yearly overview data...')
      loadYearlyOverview(state.year).then((overview) => {
        setOverviewState(overview)
        console.log('Loading monthly revision cards...')
        setMonthCards(getMonthCards())
      })
    })
  }, [state.year])

  useEffect(() => {
    console.log('Loading monthly revision cards...')
    setMonthCards(getMonthCards())
  }, [state.sentMonths])

  function getMonthCards(): JSX.Element {
    return (
      <React.Fragment key={'mothCards'}>
        {state.overallNumbers.map((nOverall, month) => {
          month += 1
          const [statusStyle, isEmpty] = getStatusStyleInfo(month)
          return (
            <Grid item key={month} xs={12} sm={6} md={4}>
              <Card className={classes.card + statusStyle}>
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {getMonthName(month)}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="h2">
                    {strings.overview.nItems}: {nOverall}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    disabled={isEmpty}
                    onClick={() => openMonthlyRevision(month)}
                  >
                    {strings.overview.openRevision}
                  </Button>
                  {!isEmpty && getMonthlyStatus(month) == MonthlyStatus.SENT && (
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => setEditableAgainStatus(month)}
                    >
                      {strings.overview.setSemiRevised}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <div className={classes.root}>
      <LoadToasts />
      <Header history={history} />

      <main className={classes.main}>
        {/* Usage information and functional menu items come into this container */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              noWrap={true}
              gutterBottom
            >
              {strings.overview.header}
            </Typography>

            <Typography
              variant="h6"
              align="justify"
              color="textSecondary"
              paragraph
            >
              {strings.overview.description}
            </Typography>

            <div>
              {/* Year selector */}
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  htmlFor="outlined-year-native-simple"
                  className={classes.selectorLabel}
                >
                  {strings.overview.year}
                </InputLabel>
                <Select
                  native
                  value={state.year}
                  onChange={(event: MaterialUISelectEvent) => {
                    setState({...state, year: event.target.value as number})
                  }}
                  label="year"
                  inputProps={{
                    name: 'year',
                    id: 'outlined-year-native-simple',
                  }}
                >
                  {getYearList().map((year) => (
                    <option key={`option-${year}`} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Container>
        </div>
        {/* List of monthly revisions of the selected year */}
        <Container className={classes.cardGrid} maxWidth="md">
          <Container className={classes.help} maxWidth="xs">
            {[
              strings.overview.help1,
              strings.overview.help2,
              strings.overview.help3,
            ].map((help, index) => (
              <Typography
                component={'span'}
                variant="body2"
                align="left"
                color="textPrimary"
                paragraph
                key={`help_${index}`}
              >
                {help}
                <Divider />
              </Typography>
            ))}
          </Container>

          <Grid container spacing={4}>
            {monthCards}
          </Grid>
        </Container>
      </main>

      <Footer />
    </div>
  )
}
export default Overview
