import React, {useEffect, useState} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import {LocalStorageKeys, strings} from '../Resources'
import {useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {FormData} from '../Resources'
import {isAuthenticationSuccessful} from '../backend/SessionManagement'
import {loginStyles} from './Styles'
import Footer from './Footer'
import LoadToasts from './Toasts'
import {MaterialUICheckboxEvent, MaterialUITextFieldEvent} from '../types'
import {redirectToNewPasswordRequest} from '../backend/Routing'
import {validation} from '../Common'

const Login: React.FC = () => {
  const classes = loginStyles()

  const history = useHistory()

  const [rememberMe, setRememberMe] = useState(
    () =>
      JSON.parse(
        localStorage.getItem(LocalStorageKeys.RememberMe) || 'false'
      ) || false
  )
  const [username, setUsername] = useState(
    (): string =>
      // Restore form state if required
      (rememberMe && localStorage.getItem(LocalStorageKeys.Username)) || ''
  )
  const [triggerToasts, setTriggerToasts] = useState<boolean>()

  const {register, handleSubmit} = useForm<FormData>()

  const onSubmitLogin = (data: FormData) => {
    // Authentication & redirecting
    isAuthenticationSuccessful(history, data).then((isAuth) => {
      if (isAuth) {
        // Save form state
        localStorage.setItem(LocalStorageKeys.RememberMe, rememberMe)
        localStorage.setItem(LocalStorageKeys.Username, username)
      } else {
        setTriggerToasts(!triggerToasts)
      }
    })
  }

  const openNewPasswordRequest = () => redirectToNewPasswordRequest(history)

  useEffect(() => {
    validation(history).then((isSuccess) => {
      if (isSuccess) {
        console.log('Token is still valid, automatically logged in')
      }
    })
  }, [])

  return (
    <div className={classes.root}>
      <LoadToasts />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {/* Upper decoration */}
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {strings.login.signIn}
          </Typography>
          {/* Login form */}
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmitLogin)}
          >
            {/* Username */}
            <TextField
              {...register('username')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label={strings.login.email}
              value={username}
              onChange={(event: MaterialUITextFieldEvent) =>
                setUsername(event.target.value)
              }
              name="username"
              autoComplete="email"
              autoFocus
            />
            {/* Password */}
            <TextField
              {...register('password')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={strings.login.password}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* Remember me checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  {...register('rememberMe')}
                  checked={rememberMe}
                  value={rememberMe}
                  onChange={(event: MaterialUICheckboxEvent) =>
                    setRememberMe(event.target.checked)
                  }
                  color="primary"
                />
              }
              label={strings.login.rememberMe}
            />
            {/* Log-in button */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {strings.login.signIn}
            </Button>
          </form>
          {/* Forgot password checkbox */}
          <Button
            size="small"
            color="primary"
            onClick={() => openNewPasswordRequest()}
          >
            {strings.login.forgotPassword}
          </Button>
        </div>
      </Container>

      <Footer />
    </div>
  )
}
export default Login
