import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {getCurrencyString, strings, StringsType} from '../Resources'
import {revisionSummaryStyles} from './Styles'
import {CopiesOfRevisionItems, RevisionState} from './Revision'
import React from 'react'
import {ListItemIcon} from '@material-ui/core'
import {
  changeOrCopyModifiableFields,
  ModifiableFields,
} from '../backend/DataLoader'

export type RevisionSummaryProps = RevisionState & {
  originalItems: CopiesOfRevisionItems
  setItemView?: (nItem: number) => void
}

export function getChangedFields(
  index: number,
  props: RevisionSummaryProps
): string[] {
  const nItem = index + 1
  if (!props.originalItems[nItem] || !props.additionalUserInput[nItem]) {
    return []
  }
  const modifiedItem = props.items[index][0]

  const wasCorrect = props.additionalUserInput[nItem].everythingWasCorrect
  // Return changed values' keys
  return wasCorrect
    ? ['everythingWasCorrect']
    : Object.entries(changeOrCopyModifiableFields(props.originalItems[nItem]))
        .filter(
          ([key, value]) => modifiedItem[key as keyof ModifiableFields] != value
        )
        .map(([key]) => key)
        .concat()
}

const RevisionSummary: React.FC<RevisionSummaryProps> = (
  props: RevisionSummaryProps
) => {
  const classes = revisionSummaryStyles()

  const isWarningRequired = (index: number) =>
    getChangedFields(index, props).length == 0

  const goToItemView = (nItem: number) =>
    props.setItemView && props.setItemView(nItem)

  function setWarning(index: number): string {
    if (isWarningRequired(index)) {
      return ` ${classes.listItemWarning} `
    }
    return ` ${classes.listItemDone} `
  }

  const humanizeChangedValues = (
    index: number,
    field: keyof ModifiableFields
  ) =>
    `${props.originalItems[index + 1][field]} → ${props.items[index][0][field]}`

  const humanizeChangedFields = (index: number, fields: string[]): string[] =>
    fields[0] == 'everythingWasCorrect'
      ? [`${strings.revision.everythingWasCorrect}`]
      : fields.map(
          (field): string =>
            `${
              (strings.revision as StringsType)[field] || field
            }: ${humanizeChangedValues(index, field as keyof ModifiableFields)}`
        )

  const getChanges = (index: number): string =>
    humanizeChangedFields(index, getChangedFields(index, props)).join(', ')

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {strings.revision.revisionSummary.header}
      </Typography>
      <List disablePadding>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={strings.revision.revisionSummary.overallNumber}
          />
          <Typography variant="subtitle1" className={classes.total}>
            {props.items.length}
          </Typography>
        </ListItem>

        {props.items.map(([item], index) => (
          <ListItem
            alignItems="flex-start"
            className={classes.listItem + setWarning(index)}
            key={item.referenceName}
            color={'primary'}
            // eslint-disable-next-line
            button
            onClick={() => goToItemView(index + 1)}
            divider
          >
            <ListItemIcon>{index + 1}.</ListItemIcon>
            <ListItemText
              primary={item.referenceName}
              secondary={
                <React.Fragment>
                  <Typography variant="caption" gutterBottom>
                    {item.location}
                  </Typography>
                  <Typography
                    component={'span'}
                    className={classes.details}
                    variant="body2"
                    gutterBottom
                  >
                    {`${strings.revision.floorArea}: ${item.floorArea}${strings.area}, 
                    ${strings.revision.accommodationType}: ${item.accommodationType}, 
                    ${strings.revision.nRooms}: ${item.nRooms}${strings.pcs}, 
                    ${strings.revision.nHalfRooms}: ${item.nHalfRooms}${strings.pcs},
                    ${strings.revision.postcode}: ${item.postcode},
                    ${strings.revision.postalAddress}: ${item.postalAddress},
                    ${strings.revision.houseNumber}: ${item.houseNumber},
                    ${strings.revision.floor}: ${item.floor}, 
                    ${strings.revision.heating}: ${item.heating}, 
                    ${strings.revision.balconyArea}: ${item.balconyArea}${strings.area}, 
                    ${strings.revision.condition}: ${item.condition}, 
                    ${strings.revision.yearOfConstruction}: ${item.yearOfConstruction}. ${strings.year}`}
                  </Typography>
                  {!isWarningRequired(index) && (
                    <React.Fragment>
                      <Typography
                        variant="caption"
                        color="error"
                        display="block"
                      >
                        {`${
                          strings.revision.revisionSummary.changes
                        }: ${getChanges(index)}`}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        display="block"
                      >
                        {`${strings.revision.comment}: ${
                          props.additionalUserInput[index + 1].comment
                        }`}
                      </Typography>
                    </React.Fragment>
                  )}
                </React.Fragment>
              }
            />
            <Typography variant="body1">
              {getCurrencyString(item.sellingPrice)}
            </Typography>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  )
}
export default RevisionSummary
