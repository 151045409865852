import {Endpoint, getBackendUrl, LocalStorageKeys} from '../Resources'

export enum RestOperation {
  GET,
  POST,
}

// Throws Error with the HTTP response code included
export function useBackend(
  operation: RestOperation,
  endpoint: Endpoint | string,
  // eslint-disable-next-line
  data?: any | undefined,
  options?: RequestInit,
  authNeeded = false
  // eslint-disable-next-line
): Promise<any | void> {
  console.log(`Use backend - Operation: ${RestOperation[operation]}`)
  const headers = new Headers()
  if (authNeeded) {
    headers.set(
      'Authorization',
      localStorage.getItem(LocalStorageKeys.Token) || 'Bearer '
    )
  }
  return (
    fetch(getBackendUrl(endpoint), {
      method: RestOperation[operation],
      headers: headers,
      body: data,
      ...options,
    })
      // Status is 200?
      .then(async (response) => {
        if (!response.ok) {
          await response.json()
          return Promise.reject(new Error(response.status.toString()))
        } else {
          return response.json()
        }
      })
      // Return body
      .then((data) => data)
      // Throw error if status code was not 200 or anything else happened
      .catch((error) => {
        return Promise.reject(error)
      })
  )
}
