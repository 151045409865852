import {makeStyles, StyleRules, Theme} from '@material-ui/core/styles'

const rootStyles = (theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    main: {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(8),
      },
      marginBottom: theme.spacing(2),
    },
  } as StyleRules<'root' | 'main'>
}

const itemColoringStyles = {
  listItemWarning: {
    backgroundColor: 'lightyellow',
  },
  listItemWarningLight: {
    backgroundImage: 'linear-gradient(lightyellow, lightyellow, lightgreen)',
  },
  listItemDone: {
    backgroundColor: 'lightgreen',
  },
}

const selectorSpacing = (theme: Theme) => {
  return {
    selectorLabel: {
      marginTop: theme.spacing(-1),
    },
  }
}

export const headerStyles = makeStyles((theme) => ({
  appBar: {
    position: 'static',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {},
  toolbarBox: {
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit',
      alignItems: 'center',
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  toolbarTitle: {
    fontFamily: 'serif',
  },
  logo: {
    maxHeight: 48,
  },
}))

export const footerStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(1, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}))

export const loginStyles = makeStyles((theme) => ({
  ...rootStyles(theme),
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export const revisionStyles = makeStyles((theme) => ({
  ...rootStyles(theme),
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

export const overviewStyles = makeStyles((theme) => ({
  ...rootStyles(theme),
  ...itemColoringStyles,
  ...selectorSpacing(theme),
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 0),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  help: {
    border: '4px solid',
    borderImage: 'linear-gradient(to bottom, yellow, greenyellow, white) 0 1',
  },
}))

export const revisionSummaryStyles = makeStyles((theme) => ({
  ...rootStyles(theme),
  ...itemColoringStyles,
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  details: {
    display: 'block',
  },
}))

export const newPasswordRequestStyles = loginStyles
