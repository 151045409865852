import React, {useCallback, useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import NumberFormat from 'react-number-format'
import Checkbox from '@material-ui/core/Checkbox'
import {
  AccommodationType,
  ConditionType,
  FloorType,
  HeatingType,
  strings,
} from '../Resources'
import {InputAdornment, InputLabel, Select} from '@material-ui/core'
import {ModifiableFields} from '../backend/DataLoader'
import {MaterialUICheckboxEvent, MaterialUISelectEvent} from '../types'

export type AdditionalUserInput = {
  // name wasEverythingOk is also used in RevisionSummary as a string, take care
  everythingWasCorrect: boolean
  comment: string
}

export type RevisionItemProps = ModifiableFields &
  AdditionalUserInput & {
    number: number
    overallNumber: number
    predictedPrice: number
    reference: string
  }

export type RevisionItemPropsWithCallback = RevisionItemProps & {
  registerChanges: (getItemState: () => RevisionItemProps) => void
}

// eslint-disable-next-line
function NumberFormatCustom(props: any) {
  const {inputRef, onChange, ...other} = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator={' '}
      isNumericString
    />
  )
}

const RevisionItem: React.FC<RevisionItemPropsWithCallback> = (
  props: RevisionItemPropsWithCallback
) => {
  const [state, setState] = useState<RevisionItemProps>(props)
  const getState = useCallback(() => state, [state])

  useEffect(() => {
    console.log('Register changes...')
    props.registerChanges(getState)
  }, [state])

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {strings.revision.item}: {props.number}/{props.overallNumber}.
      </Typography>

      {(state.everythingWasCorrect && <></>) || (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              disabled
              id="reference"
              name="reference"
              label={strings.revision.reference}
              defaultValue={props.reference}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="predictedPrice"
              name="predictedPrice"
              label={strings.revision.predictedPrice}
              defaultValue={props.predictedPrice}
              disabled
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {strings.currency}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="location"
              name="location"
              label={strings.revision.location}
              value={state.location}
              onChange={(event) =>
                setState({...state, location: event.target.value})
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="postcode"
              name="postcode"
              label={strings.revision.postcode}
              value={state.postcode}
              onChange={(event) =>
                setState({...state, postcode: event.target.value})
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="postalAddress"
              name="postalAddress"
              label={strings.revision.postalAddress}
              value={state.postalAddress}
              onChange={(event) =>
                setState({...state, postalAddress: event.target.value})
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="houseNumber"
              name="houseNumber"
              label={strings.revision.houseNumber}
              value={state.houseNumber}
              onChange={(event) =>
                setState({...state, houseNumber: event.target.value})
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="sellingPrice"
              name="sellingPrice"
              label={strings.revision.sellingPrice}
              value={state.sellingPrice}
              onChange={(event) =>
                setState({
                  ...state,
                  sellingPrice: Number(event.target.value),
                })
              }
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {strings.currency}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="floorArea"
              name="floorArea"
              type="number"
              label={strings.revision.floorArea}
              value={state.floorArea}
              onChange={(event) =>
                setState({...state, floorArea: Number(event.target.value)})
              }
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {strings.area}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="balconyArea"
              name="balconyArea"
              type="number"
              label={strings.revision.balconyArea}
              value={state.balconyArea}
              onChange={(event) =>
                setState({...state, balconyArea: Number(event.target.value)})
              }
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {strings.area}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="outlined-accommodation-type-native-simple">
              {strings.revision.accommodationType}
            </InputLabel>
            <Select
              native
              value={state.accommodationType}
              onChange={(event: MaterialUISelectEvent) => {
                setState({
                  ...state,
                  accommodationType: event.target.value as AccommodationType,
                })
              }}
              label="accommodationType"
              inputProps={{
                name: 'accommodationType',
                id: 'outlined-accommodation-type-native-simple',
              }}
            >
              {Object.entries(AccommodationType).map(([, accomodation]) => (
                <option key={`option-${accomodation}`} value={accomodation}>
                  {accomodation}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="outlined-floor-native-simple">
              {strings.revision.floor}
            </InputLabel>
            <Select
              native
              value={state.floor}
              onChange={(event: MaterialUISelectEvent) => {
                setState({
                  ...state,
                  floor: event.target.value as FloorType,
                })
              }}
              label="floor"
              inputProps={{
                name: 'floor',
                id: 'outlined-floor-native-simple',
              }}
            >
              {Object.entries(FloorType).map(([, floor]) => (
                <option key={`option-${floor}`} value={floor}>
                  {floor}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="outlined-condition-native-simple">
              {strings.revision.condition}
            </InputLabel>
            <Select
              native
              value={state.condition}
              onChange={(event: MaterialUISelectEvent) => {
                setState({
                  ...state,
                  condition: event.target.value as ConditionType,
                })
              }}
              label="condition"
              inputProps={{
                name: 'condition',
                id: 'outlined-condition-native-simple',
              }}
            >
              {Object.entries(ConditionType).map(([, condition]) => (
                <option key={`option-${condition}`} value={condition}>
                  {condition}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="yearOfConstruction"
              name="yearOfConstruction"
              type="number"
              label={strings.revision.yearOfConstruction}
              value={state.yearOfConstruction}
              onChange={(event) =>
                setState({
                  ...state,
                  yearOfConstruction: Number(event.target.value),
                })
              }
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {strings.year}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="nRooms"
              name="nRooms"
              type="number"
              label={strings.revision.nRooms}
              value={state.nRooms}
              onChange={(event) =>
                setState({...state, nRooms: Number(event.target.value)})
              }
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {strings.pcs}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="nHalfRooms"
              name="nHalfRooms"
              type="number"
              label={strings.revision.nHalfRooms}
              value={state.nHalfRooms}
              onChange={(event) =>
                setState({...state, nHalfRooms: Number(event.target.value)})
              }
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {strings.pcs}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="outlined-heating-native-simple">
              {strings.revision.heating}
            </InputLabel>
            <Select
              native
              value={state.heating}
              onChange={(event: MaterialUISelectEvent) => {
                setState({
                  ...state,
                  heating: event.target.value as HeatingType,
                })
              }}
              label="heating"
              inputProps={{
                name: 'heating',
                id: 'outlined-heating-native-simple',
              }}
            >
              {Object.entries(HeatingType).map(([, heating]) => (
                <option key={`option-${heating}`} value={heating}>
                  {heating}
                </option>
              ))}
            </Select>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="everythingWasCorrect"
                checked={state.everythingWasCorrect || false}
                value={state.everythingWasCorrect ? 'yes' : 'no'}
                onChange={(event: MaterialUICheckboxEvent) =>
                  setState({
                    ...state,
                    everythingWasCorrect: event.target.checked,
                  })
                }
              />
            }
            label={strings.revision.everythingWasCorrect}
          />
        </Grid>
        <Typography variant="body2" color="textSecondary">
          {strings.revision.reasonDescription2}
        </Typography>
        <Grid item xs={12}>
          <TextField
            id="reasonDescription"
            name="reasonDescription"
            label={strings.revision.reasonDescription}
            multiline
            fullWidth
            value={state.comment}
            inputProps={{maxLength: 200}}
            onChange={(event) =>
              setState({...state, comment: event.target.value})
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default RevisionItem
