import {
  Endpoint,
  FormData,
  LocalStorageKeys,
  strings,
  toastPush,
} from '../Resources'
import {RestOperation, useBackend} from './BackendConnector'
import {
  redirectToLogin,
  redirectToOverview,
  redirectToOverviewIfOnLogin,
} from './Routing'
import {History} from '../types'

const ACCESS_TOKEN_FIELD = 'access_token'
const TOKEN_TYPE_FIELD = 'token_type'

type TokenResponseType = {
  TOKEN_TYPE_FIELD: string
  ACCESS_TOKEN_FIELD: string
}

// eslint-disable-next-line
function isTokenResponse(token: any): token is TokenResponseType {
  return ACCESS_TOKEN_FIELD in token && TOKEN_TYPE_FIELD in token
}

function formatTokenString(response: TokenResponseType) {
  if (!isTokenResponse(response)) {
    throw new Error('Unexpected response')
  }
  if (
    response &&
    response[TOKEN_TYPE_FIELD as keyof TokenResponseType] == 'Bearer'
  ) {
    return `Bearer ${response[ACCESS_TOKEN_FIELD as keyof TokenResponseType]}`
  } else {
    throw new Error('Not supported response')
  }
}

function formatLoginParams(data: FormData): URLSearchParams {
  return new URLSearchParams({
    username: data.username,
    password: data.password,
  })
}

export async function isAuthenticationSuccessful(
  history: History,
  formData: FormData
): Promise<boolean> {
  const data = formatLoginParams(formData)

  try {
    const token = await useBackend(
      RestOperation.POST,
      Endpoint.AUTHENTICATE,
      data
    )
    // Save token
    localStorage.setItem(LocalStorageKeys.Token, formatTokenString(token))

    redirectToOverview(history)
    console.log('Authentication was successful')
    toastPush(strings.toasts.authSuccess)
    return true
  } catch (error) {
    console.log('Authentication has failed')
    switch (error.message) {
      case '400':
        toastPush(strings.toasts.authFailed)
        return false
      default:
        toastPush(strings.toasts.authFailedServerError)
        return false
    }
  }
}

export async function isValidationSuccessful(
  history: History,
  token: string | null = null
): Promise<boolean> {
  const validationToken = token || localStorage.getItem(LocalStorageKeys.Token)
  if (!validationToken) {
    console.log('No token was found')
    redirectToLogin(history)
    history.location.pathname != '/login' &&
      toastPush(strings.toasts.loginRequired)

    return new Promise(() => false)
  }

  const error401 = () => {
    toastPush(strings.toasts.loginRequired)
    redirectToLogin(history)
    return false
  }

  try {
    const user = await useBackend(
      RestOperation.POST,
      Endpoint.VALIDATE,
      JSON.stringify({
        token: validationToken,
      })
    )
    if (!user) {
      return error401()
    }
    // Save username
    localStorage.setItem(LocalStorageKeys.Username, user.username)
    redirectToOverviewIfOnLogin(history)
    console.log(`Valid token was found, username: ${user.username}`)
    return true
  } catch (e) {
    switch (e.message) {
      case '401':
        return error401()

      default:
        toastPush(strings.toasts.loginRequiredServerError)
        redirectToLogin(history)
        return false
    }
  }
}

export async function isTokenRefreshSuccessful(
  history: History
): Promise<boolean> {
  const token = localStorage.getItem(LocalStorageKeys.Token)
  if (!token) {
    console.log('No token was found')
    redirectToLogin(history)
    history.location.pathname != '/login' &&
      toastPush(strings.toasts.loginRequired)

    return new Promise(() => false)
  }

  try {
    const newToken = await useBackend(
      RestOperation.POST,
      Endpoint.REFRESH_TOKEN,
      JSON.stringify({
        token: token,
      })
    )
    // Save token
    localStorage.setItem(LocalStorageKeys.Token, formatTokenString(newToken))

    console.log('Token refreshing was successful')
    return true
  } catch (error) {
    console.log('Token refreshing has failed')
    switch (error.message) {
      case '400':
        toastPush(strings.toasts.loginRequired)
        return false
      default:
        toastPush(strings.toasts.loginRequiredServerError)
        return false
    }
  }
}

export async function isPasswordRequestSuccessful(
  history: History,
  username: string
): Promise<boolean> {
  const data = JSON.stringify({
    username: username,
  })
  try {
    const isExistingUser = await useBackend(
      RestOperation.POST,
      Endpoint.SEND_PASSWORD_REQUEST,
      data
    )
    if (!isExistingUser) {
      toastPush(strings.toasts.userDoesntExist)
    }
    return isExistingUser
  } catch (e) {
    console.log('User checking has failed')
    toastPush(strings.toasts.userDoesntExistServerError)
    return false
  }
}

export function invalidatePasswordToken(token: string): void {
  const data = JSON.stringify({
    token: token,
  })
  useBackend(RestOperation.POST, Endpoint.INVALIDATE_TOKEN, data).then(() => {
    console.log('Invalidate password token...')
  })
}

export async function isPasswordUpdated(
  token: string,
  password: string
): Promise<boolean> {
  const data = JSON.stringify({
    token: token,
    password: password,
  })
  try {
    return await useBackend(RestOperation.POST, Endpoint.UPDATE_PASSWORD, data)
  } catch (e) {
    return false
  }
}
