import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {LocalStorageKeys, strings, toastPush} from '../Resources'
import React from 'react'
import {headerStyles} from './Styles'
import {Button, Hidden, Link} from '@material-ui/core'
import {redirectToLogin} from '../backend/Routing'
import {History} from '../types'

type HeaderProps = {
  history: History
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const classes = headerStyles()

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar color="default" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <img src="/logo192.png" alt="logo" className={classes.logo} />
          <Hidden xsDown implementation="css">
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.toolbarTitle}
            >
              <Link color={'inherit'} href={'/'}>
                {strings.companyName}
              </Link>
            </Typography>
          </Hidden>

          <div className={classes.toolbarBox}>
            <Typography
              variant="subtitle2"
              color="inherit"
              className={classes.link}
            >
              <Link color={'error'} href={'/create_new_password'}>
                {strings.createNewPasswordHeader}
              </Link>
            </Typography>

            <Typography variant="subtitle1" color="inherit">
              {localStorage.getItem(LocalStorageKeys.Username)}
            </Typography>

            <Button
              color="secondary"
              variant="outlined"
              size="small"
              className={classes.link}
              onClick={() => {
                console.log('Logging out...')
                localStorage.removeItem(LocalStorageKeys.Token)
                toastPush(strings.toasts.loggingOut)
                redirectToLogin(props.history)
              }}
            >
              {strings.header.signOut}
            </Button>
          </div>

          <Hidden smUp implementation="css"></Hidden>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}
export default Header
