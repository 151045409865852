import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import {footerStyles} from './Styles'
import {strings} from '../Resources'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      <Link color="inherit" href="https://dh.hu/">
        {'Duna House Franchise Kft.'}
      </Link>
    </Typography>
  )
}

const Footer: React.FC = () => {
  const classes = footerStyles()

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Typography variant="body1">{strings.footerText}</Typography>
        <Copyright />
      </Container>
    </footer>
  )
}
export default Footer
