import React, {useCallback, useEffect, useState} from 'react'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import RevisionItem, {
  AdditionalUserInput,
  RevisionItemProps,
} from './RevisionItem'
import RevisionSummary, {getChangedFields} from './RevisionSummary'
import {getTimestamp, strings, toastPush} from '../Resources'
import {useHistory, useParams} from 'react-router-dom'
import {revisionStyles} from './Styles'
import Header from './Header'
import Footer from './Footer'
import {redirectToOverview} from '../backend/Routing'
import {
  changeOrCopyModifiableFields,
  isSendMonthlyRevision,
  loadMonthlyRevision,
  MonthlyStatus,
  RevisionItemSchema,
} from '../backend/DataLoader'
import {
  isTokenRefreshSuccessful,
  isValidationSuccessful,
} from '../backend/SessionManagement'
import assert from 'assert'
import Swal from 'sweetalert2'
import LoadToasts from './Toasts'
import {useTheme} from '@material-ui/core'

export type RevisionState = {
  status: MonthlyStatus
  items: RevisionItemSchema[]
  additionalUserInput: {[nItem: number]: AdditionalUserInput}
}

export type CopiesOfRevisionItems = {[nItem: number]: RevisionItemProps}

export type RevisionProps = {
  status?: MonthlyStatus
}

const Revision: React.FC<RevisionProps> = (props: RevisionProps) => {
  const theme = useTheme()

  const classes = revisionStyles(theme)

  // Required for mobile-compatibility purposes
  const isSmallScreen = () => window.innerWidth < theme.breakpoints.values['sm']
  const [smallScreen, setSmallScreen] = useState(isSmallScreen)
  window.addEventListener('resize', () => setSmallScreen(isSmallScreen()))

  const history = useHistory()

  const parseNumbers = ({year, month}: {year: string; month: string}) => ({
    year: Number(year) || 0,
    month: Number(month) || 0,
  })
  // Parse them from URL path
  const {year, month} = parseNumbers(useParams<{year: string; month: string}>())

  const [state] = useState<RevisionState>({
    status: MonthlyStatus.SENT,
    items: [],
    additionalUserInput: {},
  })

  const [nOverall, setNOverall] = useState<number | null>(null)

  const [viewCounter, setViewCounter] = useState(0)

  const [content, setContent] = useState<JSX.Element>()

  const [originalItems] = useState<CopiesOfRevisionItems>({})

  const [tokenRefresh, setTokenRefresh] = useState<boolean>()

  function saveOriginalItem(itemFromChild: RevisionItemProps) {
    // Save only once, otherwise it would overwrite the true original version with modified versions
    const nItem = itemFromChild.number
    if (nItem in originalItems) {
      return
    }
    console.log('Save original items...')
    originalItems[nItem] = getRevisionItemProps(nItem)
  }

  const registerChanges = useCallback(
    (getItemState: () => RevisionItemProps) => {
      const item = getItemState()
      saveOriginalItem(item)
      const nItem = item.number
      const itemIdx = nItem - 1
      const itemToChange = state.items[itemIdx][0]
      changeOrCopyModifiableFields(item, itemToChange)
      // Save comment field and wasOk checkbox
      state.additionalUserInput[nItem] = {
        everythingWasCorrect: item.everythingWasCorrect,
        comment: item.comment,
      }
      // save delta
      state.items[itemIdx][2] = {
        ...changeOrCopyModifiableFields(item),
        ...state.additionalUserInput[nItem],
      }
    },
    [state.items]
  )

  const isStatusSent = () =>
    state.status == MonthlyStatus.SENT &&
    props.status != MonthlyStatus.EDITABLE_AGAIN

  const prepareStateForSubmitting = () => {
    const nItems = state.items.length
    filterOutNonChanged()
    const nItemsChanged = state.items.length

    if (nItemsChanged == nItems) {
      state.status = MonthlyStatus.SENT
      return
    }
    if (nItemsChanged == 0) {
      // There were no changes
      state.status = MonthlyStatus.UNREVISED
      return
    }
    // Some were changed, some not
    state.status = MonthlyStatus.SEMI_REVISED
  }

  const filterOutNonChanged = () =>
    (state.items = state.items.filter(
      (value, index) =>
        value[2] &&
        getChangedFields(index, {...state, originalItems}).length > 0
    ))

  const handleSubmit = () => {
    console.log('Submitting revision...')
    Swal.fire({
      title: strings.revision.finalMessage.question,
      text: strings.revision.finalMessage.questionDetails,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.revision.finalMessage.yes,
      cancelButtonText: strings.revision.finalMessage.cancel,
    }).then((result) => {
      if (result.isConfirmed) {
        prepareStateForSubmitting()
        console.log('Status', state.status)
        isSendMonthlyRevision(year, month, state).then((isSent: boolean) => {
          if (isSent) {
            toastPush(strings.toasts.revisionSent)
          } else {
            toastPush(strings.toasts.revisionSendingFailed)
          }
          handleNext()
          return
        })
      }
      return
    })
  }

  const handleNext = () => {
    if (selectView(viewCounter + 1) == View.Sent) {
      console.log('Revision done')
      redirectToOverview(history)
      return
    }
    console.log('Next')
    setViewCounter(viewCounter + 1)
    window.scrollTo(0, 0)
  }

  const handleBack = () => {
    console.log('Back')
    setViewCounter(viewCounter - 1)
    if (selectView(viewCounter - 1) == View.Sent) {
      exitRevisionWithoutSending()
    }
  }

  function initByDelta(data: RevisionItemSchema[]) {
    data.forEach((value, index) => {
      const nItem = index + 1
      const itemToChange = state.items[index][0]
      originalItems[nItem] = getRevisionItemProps(nItem)
      const itemDelta = state.items[index][2]
      if (!itemDelta) {
        return
      }
      changeOrCopyModifiableFields(itemDelta, itemToChange)
      // Save comment field and wasOk checkbox
      state.additionalUserInput[nItem] = {
        everythingWasCorrect: itemDelta.everythingWasCorrect,
        comment: itemDelta.comment,
      }
    })
  }

  useEffect(() => {
    props = history.location.state as RevisionProps
  })

  useEffect(() => {
    console.log('Validation started...')
    isValidationSuccessful(history)
      .then((isValid) => {
        console.log(`Validation's result: ${isValid ? 'success' : 'failed'}`)
        if (isValid) {
          return
        }
      })
      .then(() => {
        console.log('Getting monthly revision data...')
        loadMonthlyRevision(year, month).then((data) => {
          if (data.length < 1) {
            console.log('No available monthly data')
            redirectToOverview(history)
            return
          }
          state.items = data
          setNOverall(data.length)
          state.status = data[0][1]
          initByDelta(data)

          console.log('Loading content...')
          setContent(getContent())
        })
      })
  }, [])

  useEffect(() => {
    if (!content) {
      return
    }
    console.log('View counter stepped, loading content...')
    setContent(getContent())
  }, [viewCounter, smallScreen])

  useEffect(() => {
    console.log('Token refresh timer has been started...')
    setTimeout(() => {
      isTokenRefreshSuccessful(history).then((refreshSucceeded) => {
        if (refreshSucceeded) {
          setTokenRefresh(!tokenRefresh)
          return
        }
      })
    }, 300000) // token refresh in every 5 minutes
  }, [tokenRefresh])

  enum View {
    Overview,
    Item,
    Summary,
    FinalMessage,
    Sent,
  }

  const exitRevisionWithoutSending = () => {
    console.log('Revision closed')
    toastPush(strings.toasts.revisionEndedWithoutSending)
    redirectToOverview(history)
  }

  const setItemView = useCallback(
    (nItem: number) => setViewCounter(nItem),
    [viewCounter]
  )

  function selectView(step: number): number {
    const overallNumber = nOverall ?? state.items.length
    switch (step) {
      case 0:
        return View.Overview
      case overallNumber + 1:
        return View.Summary
      case overallNumber + 2:
        return View.FinalMessage
      case overallNumber + 3:
        return View.Sent
      case -1:
        return View.Sent
      default:
        if (step < 0) {
          return View.Sent
        }
        return isStatusSent() ? View.Sent : View.Item
    }
  }

  function getRevisionItemProps(nItem: number): RevisionItemProps {
    const itemIdx = nItem - 1
    assert(state.items.length > itemIdx)
    const item = state.items[itemIdx][0]

    if (!state.additionalUserInput[nItem]) {
      state.additionalUserInput[nItem] = {
        everythingWasCorrect: false,
        comment: '',
      }
    }
    const additionalUserInput = state.additionalUserInput[nItem]

    return {
      number: nItem,
      overallNumber: state.items.length,
      predictedPrice: item.predictedPrice,
      reference: item.referenceName,
      everythingWasCorrect: additionalUserInput.everythingWasCorrect,
      comment: additionalUserInput.comment,
      ...changeOrCopyModifiableFields(item),
    }
  }

  function getStepRelatedContent(step: number): JSX.Element {
    const nItem = step

    const finalMessage = () => (
      <React.Fragment key={'finalMessage'}>
        <Typography variant="h5" gutterBottom>
          {strings.revision.finalMessage.header}
        </Typography>
        <Typography variant="subtitle1">
          {strings.revision.finalMessage.details}
        </Typography>
      </React.Fragment>
    )

    switch (selectView(step)) {
      case View.Overview:
        return (
          <RevisionSummary
            {...state}
            originalItems={originalItems}
            setItemView={
              (!isStatusSent() && setItemView) ||
              (() => {
                return
              })
            }
            key={'overview'}
          />
        )
      case View.Item:
        return nItem > 0 ? (
          <RevisionItem
            {...getRevisionItemProps(nItem)}
            registerChanges={registerChanges}
            key={'item_' + nItem}
          />
        ) : (
          <></>
        )
      case View.Summary:
        return (
          <RevisionSummary
            {...state}
            originalItems={originalItems}
            setItemView={setItemView}
            key={'summary'}
          />
        )
      case View.FinalMessage:
        return finalMessage()
      default:
        return <></>
    }
  }

  function getStepperState(): JSX.Element {
    const steps = [strings.revision.overviewItems]
    if (!isStatusSent()) {
      steps.push(strings.revision.reviseItems, strings.revision.summary)
    }

    return (
      <Stepper
        orientation={smallScreen ? 'vertical' : 'horizontal'}
        activeStep={selectView(viewCounter)}
        className={classes.stepper}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }

  const isBackButtonRequired = () => {
    return (
      !isStatusSent() &&
      [View.Item, View.Summary, View.Overview].includes(selectView(viewCounter))
    )
  }

  function getStepRelatedButtons(step: number): JSX.Element {
    const isSummary = () => selectView(viewCounter) == View.Summary
    return (
      <div className={classes.buttons}>
        {/* Back button */}
        {isBackButtonRequired() && (
          <Button onClick={handleBack} className={classes.button}>
            {strings.revision.backButton}
          </Button>
        )}
        {/* Next or Send button */}
        <Button
          variant="contained"
          color="primary"
          onClick={isSummary() ? handleSubmit : handleNext}
          className={classes.button}
        >
          {isSummary()
            ? strings.revision.sendButton
            : selectView(viewCounter + 1) == View.Sent
            ? strings.revision.backToOverview
            : strings.revision.nextButton}
        </Button>
        {/* To Summary button */}
        {selectView(step) == View.Item && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setItemView(state.items.length + 1)
            }}
            className={classes.button}
          >
            {strings.revision.toSummaryButton}
          </Button>
        )}
        {/* Cancel button */}
        {isSummary() && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={exitRevisionWithoutSending}
            className={classes.button}
          >
            {strings.revision.cancelButton}
          </Button>
        )}
      </div>
    )
  }

  function getContent(): JSX.Element {
    return (
      <React.Fragment key={'content'}>
        {getStepperState()}
        {getStepRelatedContent(viewCounter)}
        {getStepRelatedButtons(viewCounter)}
      </React.Fragment>
    )
  }

  return (
    <div className={classes.root}>
      <LoadToasts />
      <Header history={history} />

      <main className={`${classes.layout} ${classes.main}`}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            {getTimestamp(month, year)}
          </Typography>

          {content}
        </Paper>
      </main>

      <Footer />
    </div>
  )
}
export default Revision
