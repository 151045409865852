import React, {useState} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import {strings, toastPush} from '../Resources'
import {useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {FormData} from '../Resources'
import {newPasswordRequestStyles} from './Styles'
import Footer from './Footer'
import LoadToasts, {showToasts} from './Toasts'
import {MaterialUITextFieldEvent} from '../types'
import {redirectToLogin} from '../backend/Routing'
import {isPasswordRequestSuccessful} from '../backend/SessionManagement'

const NewPasswordRequest: React.FC = () => {
  const classes = newPasswordRequestStyles()
  const history = useHistory()

  const [username, setUsername] = useState<string>('')

  const [isButtonDisabled, setisButtonDisabled] = useState(false)

  const {register, handleSubmit} = useForm<FormData>()

  const onSubmitPasswordRequest = () => {
    setisButtonDisabled(true)
    isPasswordRequestSuccessful(history, username).then(
      (isSuccessful: boolean) => {
        if (isSuccessful) {
          toastPush(strings.toasts.newPasswordLinkSent)
          redirectToLogin(history)
        } else {
          setisButtonDisabled(false)
          showToasts()
        }
      }
    )
  }

  return (
    <div className={classes.root}>
      <LoadToasts />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {/* Upper decoration */}
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {strings.login.forgotPassword}
          </Typography>
          {/* Login form */}
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmitPasswordRequest)}
          >
            {/* Email address */}
            <TextField
              {...register('username')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label={strings.login.email}
              value={username}
              onChange={(event: MaterialUITextFieldEvent) =>
                setUsername(event.target.value)
              }
              name="username"
              autoComplete="email"
              autoFocus
            />
            {/* Form submit button */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isButtonDisabled}
            >
              {strings.login.sendNewPasswordRequest}
            </Button>
          </form>
        </div>
      </Container>

      <Footer />
    </div>
  )
}
export default NewPasswordRequest
