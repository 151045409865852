import {
  AccommodationType,
  ConditionType,
  Endpoint,
  FloorType,
  HeatingType,
  strings,
  toastPush,
} from '../Resources'
import {RestOperation, useBackend} from './BackendConnector'
import {RevisionState} from '../pages/Revision'
import {AdditionalUserInput} from '../pages/RevisionItem'

// TODO: outsource redundant code with one more abstraction layer to BackendConnector.ts
export async function loadMonthlyRevision(
  year: number,
  month: number
): Promise<RevisionItemSchema[]> {
  try {
    const monthlyRevision = await useBackend(
      RestOperation.GET,
      `${Endpoint.REVISIONS}/${year}/${month}`,
      undefined,
      undefined,
      true
    )
    console.log('Monthly revision was loaded successfully')
    toastPush(strings.toasts.monthlyRevisionLoaded)
    return monthlyRevision
  } catch (error) {
    console.log('Monthly revision loading has failed')
    const emptyMonthlyRevision: RevisionItemSchema[] = []
    switch (error.message) {
      case '501':
        toastPush(strings.toasts.monthlyRevisionLoadingFailed)
        return emptyMonthlyRevision
      default:
        toastPush(strings.toasts.monthlyRevisionLoadingFailedServerError)
        return emptyMonthlyRevision
    }
  }
}

export async function isSendMonthlyRevision(
  year: number,
  month: number,
  revision: RevisionState
): Promise<boolean> {
  const data = JSON.stringify({
    revision_item_ids: revision.items.map((item) => item[0].id),
    revision_item_deltas: revision.items.map((item) => item[2]),
    monthly_status: revision.status,
  })
  try {
    await useBackend(
      RestOperation.POST,
      `${Endpoint.REVISIONS}/${year}/${month}`,
      data,
      undefined,
      true
    )
    return true
  } catch (e) {
    return false
  }
}

export type Month = {
  month: number
  status: MonthlyStatus
}

export type OverviewDataSchema = {
  year: number
  unsentMonths: Month[]
  overallNumbers: number[] // must have 12 elements for the 12 months
}

export type ModifiableFields = {
  floorArea: number
  sellingPrice: number
  location: string
  accommodationType: AccommodationType
  nRooms: number
  nHalfRooms: number
  balconyArea: number
  floor: FloorType
  condition: ConditionType
  yearOfConstruction: number
  heating: HeatingType
  postcode: string
  postalAddress: string
  houseNumber: string
}

/* Use this for setting values */
export function changeOrCopyModifiableFields(
  fromObject: ModifiableFields,
  toObject?: ModifiableFields
): ModifiableFields {
  if (toObject) {
    toObject.floorArea = fromObject.floorArea
    toObject.sellingPrice = fromObject.sellingPrice
    toObject.location = fromObject.location
    toObject.accommodationType = fromObject.accommodationType
    toObject.nRooms = fromObject.nRooms
    toObject.nHalfRooms = fromObject.nHalfRooms
    toObject.balconyArea = fromObject.balconyArea
    toObject.floor = fromObject.floor
    toObject.condition = fromObject.condition
    toObject.yearOfConstruction = fromObject.yearOfConstruction
    toObject.heating = fromObject.heating
    toObject.postcode = fromObject.postcode
    toObject.postalAddress = fromObject.postalAddress
    toObject.houseNumber = fromObject.houseNumber
    return toObject
  }
  return {
    floorArea: fromObject.floorArea,
    sellingPrice: fromObject.sellingPrice,
    location: fromObject.location,
    accommodationType: fromObject.accommodationType,
    nRooms: fromObject.nRooms,
    nHalfRooms: fromObject.nHalfRooms,
    balconyArea: fromObject.balconyArea,
    floor: fromObject.floor,
    condition: fromObject.condition,
    yearOfConstruction: fromObject.yearOfConstruction,
    heating: fromObject.heating,
    postcode: fromObject.postcode,
    postalAddress: fromObject.postalAddress,
    houseNumber: fromObject.houseNumber,
  }
}

export type RevisionItemSchema = [
  ModifiableFields & {
    id: number
    innerId: string
    referenceName: string
    predictedPrice: number
    year: number
    month: number
  },
  number, // monthly status
  ModifiableFields & AdditionalUserInput // revision item delta
]

export enum MonthlyStatus {
  SENT,
  UNREVISED,
  SEMI_REVISED,
  EDITABLE_AGAIN,
}

type OverviewResponse = number[]

function createOverviewResult(
  year: number,
  response: OverviewResponse[]
): OverviewDataSchema {
  const unsentMonths: Month[] = response
    .map(([month, , status]) => ({
      month: month,
      status: status,
    }))
    .filter((m) => m.status != MonthlyStatus.SENT)

  const overallNumbers = Array(12)
    .fill(0)
    .map(
      (_, monthIdx) =>
        Object.assign([], response)
          .filter(([month, ,]) => month == monthIdx + 1)
          ?.map(([, nItems]) => nItems)[0] || 0
    )

  return {
    year: year,
    unsentMonths: unsentMonths,
    overallNumbers: overallNumbers,
  }
}

export async function loadYearlyOverview(
  year: number
): Promise<OverviewDataSchema> {
  try {
    const overview = await useBackend(
      RestOperation.GET,
      `${Endpoint.REVISIONS}/${year}`,
      undefined,
      undefined,
      true
    )
    const result = createOverviewResult(year, overview)
    console.log('Overview was loaded')
    toastPush(strings.toasts.overviewLoaded)
    return result
  } catch (error) {
    console.log('Overview loading has failed')
    const emptyResult = createOverviewResult(year, [])
    switch (error.message) {
      case '501':
        toastPush(strings.toasts.overviewLoadingFailed)
        return emptyResult
      default:
        toastPush(strings.toasts.overviewLoadingFailedServerError)
        return emptyResult
    }
  }
}
