import {History} from '../types'
import {MonthlyStatus} from './DataLoader'

export function redirectToLogin(history: History): void {
  if (history.location.pathname != '/login') {
    console.log('Redirection: /... -> /login')
    history.push('/login')
  }
  return
}

export function redirectToOverview(history: History): void {
  console.log('Redirection: /... -> /overview')
  history.push('/overview')
}

export function redirectToOverviewIfOnLogin(history: History): void {
  if (history.location.pathname == '/login') {
    history.push('/overview')
  }
}

export function redirectToMonthlyRevision(
  history: History,
  year: number,
  month: number,
  status?: MonthlyStatus
): void {
  // TODO: refactor frontend endpoints into an enum
  const path = `/revision/${year}/${month}`
  console.log(`Redirection: /... -> ${path}`)
  history.push({
    pathname: path,
    state: {status: status},
  })
}

export function redirectToNewPasswordRequest(history: History): void {
  console.log('Redirection: /... -> /new_password_request')
  history.push('/new_password_request')
}
