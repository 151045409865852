import React, {useEffect, useState} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import {LocalStorageKeys, strings, toastPush} from '../Resources'
import {useHistory, useLocation} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {FormData} from '../Resources'
import {newPasswordRequestStyles} from './Styles'
import Footer from './Footer'
import LoadToasts from './Toasts'
import {MaterialUITextFieldEvent} from '../types'
import {redirectToLogin} from '../backend/Routing'
import {
  invalidatePasswordToken,
  isPasswordUpdated,
  isValidationSuccessful,
} from '../backend/SessionManagement'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const CreateNewPassword: React.FC = () => {
  const classes = newPasswordRequestStyles()
  const history = useHistory()
  const query = useQuery()

  const [password, setPassword] = useState<string>('')
  const [passwordRe, setPasswordRe] = useState<string>('')
  const [token, setToken] = useState<string>('')

  const {register, handleSubmit} = useForm<FormData>()

  const onSubmitPasswordRequest = () => {
    isPasswordUpdated(token, password)
      .then((isSuccessful) => {
        console.log('Password update success: ', isSuccessful)
        if (isSuccessful) {
          toastPush(strings.toasts.newPasswordSet)
        } else {
          toastPush(strings.toasts.newPasswordSetInvalidToken)
        }
        console.log('Remove token from local storage...')
        localStorage.removeItem(LocalStorageKeys.Token)
      })
      .then(() => redirectToLogin(history))
  }

  const passwordsAreNotValid = (): boolean => password != passwordRe
  const getPasswordValidationErrorText = (): string =>
    passwordsAreNotValid() ? strings.login.passwordsAreNotTheSame : ''

  useEffect(() => {
    const validationToken: string =
      localStorage.getItem(LocalStorageKeys.Token) ||
      'Bearer ' + query.get('token') ||
      ''
    isValidationSuccessful(history, validationToken).then((isValidToken) => {
      console.log('Password token validity: ', isValidToken)
      if (!isValidToken) {
        toastPush(strings.toasts.newPasswordSetInvalidToken)
        redirectToLogin(history)
        return
      }
      console.log('Invalidate token')
      invalidatePasswordToken(validationToken)
      setToken(validationToken)
      console.log('Token was set')
    })
  }, [])

  return (
    <div className={classes.root}>
      <LoadToasts />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {/* Upper decoration */}
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {strings.login.forgotPassword}
          </Typography>
          {/* Login form */}
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmitPasswordRequest)}
          >
            {/* New Password */}
            <TextField
              {...register('password')}
              error={passwordsAreNotValid()}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label={strings.login.newPassword}
              type="password"
              onChange={(event: MaterialUITextFieldEvent) =>
                setPassword(event.target.value)
              }
              helperText={getPasswordValidationErrorText()}
              name="password"
              autoFocus
            />
            {/* New Password re */}
            <TextField
              error={passwordsAreNotValid()}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password_re"
              label={strings.login.newPasswordRe}
              autoComplete="current-password"
              type="password"
              onChange={(event: MaterialUITextFieldEvent) =>
                setPasswordRe(event.target.value)
              }
              helperText={getPasswordValidationErrorText()}
              name="password_re"
            />
            {/* Form submit button */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={passwordsAreNotValid()}
            >
              {strings.login.sendCreateNewPassword}
            </Button>
          </form>
        </div>
      </Container>

      <Footer />
    </div>
  )
}
export default CreateNewPassword
