import React from 'react'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Login from './pages/Login'
import Overview from './pages/Overview'
import Revision from './pages/Revision'
import NewPasswordRequest from './pages/NewPasswordRequest'
import CreateNewPassword from './pages/CreateNewPassword'

const App: React.FC = () => (
  <React.Fragment>
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. **/}
      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        <Route path="/overview">
          <Overview />
        </Route>

        <Route path="/revision/:year/:month">
          <Revision />
        </Route>

        <Route path="/new_password_request">
          <NewPasswordRequest />
        </Route>

        <Route path="/create_new_password">
          <CreateNewPassword />
        </Route>

        <Route path="/">{<Redirect to="/overview" />}</Route>
      </Switch>
    </Router>
  </React.Fragment>
)

export default App
