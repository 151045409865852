import ServerConfig from './serverConfig.json'

export function getMonthName(month: Date | number): string {
  if (typeof month === 'number') {
    const date = new Date()
    date.setDate(1)
    date.setMonth(month - 1)
    month = date
  }
  return month.toLocaleString('hu-hu', {month: 'long'})
}

export const getTimestamp = (month: number, year: number): string =>
  `${year}. ${getMonthName(month)}`

export const getCurrencyString = (price: number): string =>
  price.toLocaleString('hu-hu', {
    style: 'currency',
    currency: 'HUF',
    maximumFractionDigits: 0,
  })

export const getFloorAreaString = (area: number): string => `${area} m^2`

export const getCurrentYear = (): number => new Date().getFullYear()

export const getYearList = (extraYears = 2): number[] =>
  Array.from(
    {length: getCurrentYear() + extraYears - 2021 + 1},
    (_, i) => i + 2021
  )

export enum AccommodationType {
  FamilyHouse = 'családi ház',
  Flat = 'társasház - nem panel',
  PanelFlat = 'panel',
  Unfilled = '',
}

export enum HeatingType {
  Type1 = 'gáz (cirkó)',
  Type2 = 'házközponti egyedi méréssel',
  Type3 = 'távfűtés egyedi mérővel',
  Type4 = 'egyéb kazán',
  Type5 = 'gáz (konvektor)',
  Type6 = 'gázkazán',
  Type7 = 'távfűtés',
  Type8 = 'házközponti',
  Type9 = 'elektromos',
  Type10 = 'egyéb',
  Type11 = 'gáz (héra)',
  Type12 = 'gáz + napkollektor',
  Type13 = 'geotermikus',
  Unfilled = '',
}

export enum FloorType {
  _1 = '1',
  _2 = '2',
  _3 = '3',
  _4 = '4',
  _5 = '5',
  _6 = '6',
  _7 = '7',
  _8 = '8',
  _9 = '9',
  _10Plus = '10+',
  Basement = 'alagsor',
  Mezzanine = 'félemelet',
  SemiSuteren = 'félszuterén',
  GroundFloor = 'földszint',
  RaisedGroundFloor = 'magasföldszint',
  CellarFloor = 'pinceszint',
  Suteren = 'szuterén',
  Unfilled = '',
}

export enum ConditionType {
  _1 = '1 - Lakhatatlan',
  _2 = '2 - Felújítandó',
  _3 = '3 - Lakható',
  _4 = '4 - Jó',
  _5 = '5 - Nagyon jó',
  VeryGood = 'Nagyon jó',
  NewlyBuilt = 'új ép.',
  Unfilled = '',
}

export type StringsType = {[_: string]: StringsType | string}

export const strings = {
  header: {
    signOut: 'Kijelentkezés',
  },
  login: {
    signIn: 'Bejelentkezés',
    email: 'E-mail cím',
    password: 'Jelszó',
    newPassword: 'Új jelszó',
    newPasswordRe: 'Új jelszó ismét',
    rememberMe: 'Adataim megjegyzése',
    forgotPassword: 'Elfelejtett jelszó',
    sendNewPasswordRequest: 'Új jelszó kérése',
    sendCreateNewPassword: 'Új jelszó beállítása',
    passwordsAreNotTheSame: 'Nem egyeznek a jelszavak',
  },
  revision: {
    item: 'Tétel',
    sellingPrice: 'Eladási ár',
    predictedPrice: 'Becsült ár',
    reasonDescription: 'Kifejtett indoklás',
    reasonDescription2: 'Az indoklás csak helyesnek jelölt vagy javított tételek esetén lesz figyelembe véve.',
    everythingWasCorrect: 'Helyesek voltak az adatok, nincs változtatás',
    nextItem: 'Következő tétel',
    reference: 'Referencia',
    location: 'Település',
    floorArea: 'Alapterület',
    accommodationType: 'Jelleg',
    heating: 'Fűtés',
    nRooms: 'Szobák száma',
    nHalfRooms: 'Félszobák száma',
    balconyArea: 'Erkély területe',
    floor: 'Emelet',
    condition: 'Állapot',
    yearOfConstruction: 'Építés éve',
    postcode: 'Irányítószám',
    postalAddress: 'Közterület',
    houseNumber: 'Házszám',
    overviewItems: 'Tételek áttekintése',
    reviseItems: 'Tételek felülvizsgálata',
    summary: 'Összegzés',
    comment: 'Megjegyzés',
    finalMessage: {
      question: 'Biztosan el szeretné küldeni?',
      questionDetails:
        'Ellenőrizze, hogy vannak-e figyelmen kívül hagyott tételek. Ezek kiemelt színnel (sárga) vannak jelölve.',
      yes: 'Igen',
      cancel: 'Mégsem',
      header: 'Válasz elküldve',
      details:
        'Köszönjük a kitöltést! Kérjük, a továbbiakban is segítse munkánkat a visszajelzésével!',
    },
    backButton: 'Vissza',
    backToOverview: 'Vissza az áttekintéshez',
    sendButton: 'Válasz elküldése',
    nextButton: 'Tovább',
    cancelButton: 'Mégsem',
    toSummaryButton: 'Ugrás az elküldéshez',
    revisionSummary: {
      header: 'Kérdéses tételek',
      overallNumber: 'Tételek száma',
      changes: 'Változtatások',
    },
  },
  overview: {
    header: 'Felülvizsgálandó tételek',
    description: `Itt találhatóak meg hónapokra bontva azok az eladások, amiknél az algoritmikusan
       becsült árak jelentősen eltérnek a rögzített áraktól. Ezekről az eladásokról 
       szeretnénk visszajelzést kapni, hogy javítani lehessen az értékbecslő algoritmuson.
       Ha az ingatlan bármely tulajdonságánál hibát, vagy elírást tapasztal, kérjük javítsa ki azt
       a helyes értékre. Ha a tulajdonságok helyesek, kérjük fűzzön szöveges magyarázatot a 
       tranzakcióhoz, hogy mi lehet az oka az értékesítési és a becsült ár közötti különbségnek.`,
    help1: 'Sárga szín: Elküldésre váró, vagy még nem teljesen kitöltött hónap',
    help2: 'Zöld szín: Korábban már elküldött hónap',
    help3: 'Fehér: Nincsenek felülvizsgálandó tételek a hónapban',
    openRevision: 'Tovább',
    year: 'Év kiválasztása',
    nItems: 'Tételek száma',
    setSemiRevised: 'Legyen módosítható',
  },
  toasts: {
    authSuccess: 'Sikeres bejelentkezés',
    authFailed: 'Rossz felhasználónév vagy jelszó',
    authFailedServerError:
      'Szerveroldali probléma miatt sikertelen a bejelentkezés',
    loginRequired:
      'Bejelentkezés szükséges (jogosulatlan hozzáférés vagy lejárt munkamenet)',
    loginRequiredServerError:
      'Bejelentkezés szükséges (szerveroldali probléma)',
    loggingOut: 'Kijelentkezve',
    overviewLoaded: 'Éves adatok sikeresen betöltve',
    overviewLoadingFailed: 'Nem sikerült betölteni az adatokat a kért évhez',
    overviewLoadingFailedServerError:
      'Nem sikerült betölteni az adatokat a kért évhez (szerveroldali probléma)',
      overviewMonthSetToSemiRevised: 'A kért hónap ismét módosítható',
    monthlyRevisionLoaded: 'Havi adatok sikeresen betöltve',
    monthlyRevisionLoadingFailed:
      'Nem sikerült betölteni az adatokat a kért hónaphoz',
    monthlyRevisionLoadingFailedServerError:
      'Nem sikerült betölteni az adatokat a kért hónaphoz (szerveroldali probléma)',
    revisionEndedWithoutSending: 'Módosítások visszavonva',
    revisionSent: 'Módosítások elküldve',
    revisionSendingFailed:
      'Módosítások nem lettek elküldve (szerveroldali hiba)',
    newPasswordLinkSent:
      'Új jelszó igénylő link elküldve a felhasználó email címére',
    newPasswordSet: 'Új jelszó sikeresen beállítva',
    newPasswordSetInvalidToken:
      'Új jelszó beállítása sikertelen (hiányzó vagy lejárt azonosító)',
    userDoesntExist: 'Felhasználó nem található',
    userDoesntExistServerError:
      'Felhasználó nem található (szerveroldali hiba)',
  },
  currency: 'Ft',
  area: 'm²',
  pcs: 'db',
  year: 'év',
  companyName: 'Duna House',
  footerText: '',
  createNewPasswordHeader: 'Új jelszó beállítása',
}

export function getBackendUrl(endpoint: Endpoint | string): string {
  const server = ServerConfig.backendURL
  return server + endpoint
}

export enum Endpoint {
  USER = '/user',
  USERS = '/users',
  AUTHENTICATE = '/authenticate',
  VALIDATE = '/validate',
  REFRESH_TOKEN = '/refresh_token',
  REVISIONS = '/revisions',
  SEND_PASSWORD_REQUEST = '/send_password_request_email',
  INVALIDATE_TOKEN = '/invalidate_token',
  UPDATE_PASSWORD = '/update_password',
}

export enum LocalStorageKeys {
  Token = 'token',
  RememberMe = 'rememberMe',
  Username = 'username',
  Toasts = 'toasts',
}

export type FormData = {
  username: string
  password: string
  rememberMe: boolean
}

export type UserInfoBase = {
  username: string
}

export function toastPush(message: string): void {
  const toasts: string[] =
    JSON.parse(localStorage.getItem(LocalStorageKeys.Toasts) || 'null') || []
  toasts.push(message)
  localStorage.setItem(LocalStorageKeys.Toasts, JSON.stringify(toasts))
}

export function toastPop(): string | undefined {
  const toasts: string[] =
    JSON.parse(localStorage.getItem(LocalStorageKeys.Toasts) || 'null') || []
  const toast = toasts.pop()
  localStorage.setItem(LocalStorageKeys.Toasts, JSON.stringify(toasts))
  return toast
}

export function toastPopAll(): string[] {
  const toasts =
    JSON.parse(localStorage.getItem(LocalStorageKeys.Toasts) || 'null') || []
  localStorage.setItem(LocalStorageKeys.Toasts, JSON.stringify([]))
  return toasts
}
