import {ToastContainer, toast, Flip} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {toastPopAll} from '../Resources'
import React, {useEffect} from 'react'

const getToastsFromLocalDb = (nMax: number) => toastPopAll().slice(0, nMax)

export const showToasts = (nMax = 5): void =>
  getToastsFromLocalDb(nMax).forEach((message: string) => {
    toast.info(message)
  })

const LoadToasts: React.FC = () => {
  useEffect(() => {
    console.log('Showing toasts...')
    // Wait for page loading...
    const toastTimer = setTimeout(() => {
      showToasts()
    }, 300)
    return () => {
      clearTimeout(toastTimer)
      toast.clearWaitingQueue()
    }
  })

  return (
    <ToastContainer position="bottom-left" autoClose={5000} transition={Flip} />
  )
}
export default LoadToasts
