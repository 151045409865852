import {isValidationSuccessful} from './backend/SessionManagement'
import {History} from './types'

export async function validation(history: History): Promise<boolean> {
  console.log('Validation started...')
  // Validation & redirecting
  const isValid = await isValidationSuccessful(history)
  console.log(`Validation's result: ${isValid ? 'success' : 'failed'}`)
  return isValid
}

// TODO: common component frame comes here
